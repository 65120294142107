<template>
  <q-icon :name="iconName" v-bind="$attrs" />
</template>

<script>

const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const loadIonicon = async function loadIonicon (iconName) {
  const ion = await import('@quasar/extras/ionicons-v6')

  let ioniconName = iconName.split('-').splice(1)
  if (ioniconName.length < 2) {
    ioniconName = `ion${capitalize(ioniconName[0])}`
  } else {
    ioniconName = ioniconName.reduce((a, b) => capitalize(a) + capitalize(b))
    ioniconName = `ion${ioniconName}`
  }

  return ion[ioniconName]
}

export default {
  name: 'CustomSvgIcon',

  props: {
    name: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      iconName: ''
    }
  },

  async created () {
    if (!this.name.startsWith('ion-')) {
      this.iconName = this.name
    } else {
      const ionIcon = await loadIonicon(this.name)
      this.iconName = ionIcon
    }
  }
}
</script>

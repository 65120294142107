<template>
  <q-page class="flex flex-center" style="padding: 15px;">
    <div class="column items-center">

      <h4>{{ $t('create_an_account') }}</h4>

      <q-img src="/img/data.svg" alt='Twitter icon' height="200px" width="200px" loading="lazy"></q-img>
      <br />
      <div>
        <q-list>
          <q-item >
            <q-item-section avatar class="reduce-q-item-section-width">
              <q-icon color="positive" name="check_circle_outline" />
            </q-item-section>

            <q-item-section>{{ $t('private_downloads') }}</q-item-section>
          </q-item>

          <q-item >
            <q-item-section avatar class="reduce-q-item-section-width">
              <q-icon color="positive" name="laptop" />
            </q-item-section>

            <q-item-section>{{ $t('save_access_downloads_history') }}</q-item-section>
          </q-item>

          <q-item >
            <q-item-section avatar class="reduce-q-item-section-width">
              <q-icon color="positive" name="keyboard" />
            </q-item-section>

            <q-item-section>{{ $t('rename_downloaded_videos') }}</q-item-section>
          </q-item>

          <q-item >
            <q-item-section avatar class="reduce-q-item-section-width">
              <q-icon color="positive" name="ion-logo-google" />
            </q-item-section>

            <q-item-section>{{ $t('backup_videos_g_drive') }}</q-item-section>
          </q-item>
        </q-list>

        <br /><br />

        <q-btn rounded size="lg" color="primary" :label="$t('create_account')" @click="login()" class="full-width" />
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'Intro',

  data () {
    return {}
  },

  methods: {
    login () {
      this.$router.push({ name: 'Register' })
    }
  }
}
</script>

<style scoped>
  .reduce-q-item-section-width {
    min-width: 0px;
    padding-right: 10px;
  }
</style>

<template>
  <span>
    <q-header>
      <update-pwa v-show="updateAvailable"></update-pwa>

      <span v-show="!updateAvailable">
        <q-toolbar v-show="inverted || $q.theme === 'ios'" class="bg-white" style="height: 60px;">
          <q-btn flat dense round @click="toggleDrawer()" aria-label="Menu">
            <custom-svg-icon name="ion-menu" class="text-primary-text" />
          </q-btn>

          <q-toolbar-title class="text-center text-primary-text" style="margin-left: -40px; font-size: 19px;">
            {{ title }}
          </q-toolbar-title>
        </q-toolbar>

        <q-toolbar v-show="!(inverted || $q.theme === 'ios')" color="primary">
          <q-btn flat dense round @click="toggleDrawer()" aria-label="Menu">
            <custom-svg-icon name="ion-menu" />
          </q-btn>

            <q-toolbar-title class="text-center" style="margin-left: -45px; font-size: 19px;">
            {{ title }}
          </q-toolbar-title>
        </q-toolbar>
      </span>
    </q-header>

    <side-drawer v-model="leftDrawerOpen" />
  </span>
</template>

<script>

import SideDrawer from './SideDrawer.vue'
import UpdatePWA from './UpdatePWA.vue'
import CustomSvgIcon from './CustomSvgIcon.vue'

export default {
  name: 'HeaderBar',
  components: { SideDrawer, 'update-pwa': UpdatePWA, CustomSvgIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leftDrawerOpen: false
    }
  },

  methods: {
    toggleDrawer () {
      this.leftDrawerOpen = !this.leftDrawerOpen
    }
  }
}
</script>

<style>
.q-item__section--side > .q-icon {
  font-size: 30px;
}
</style>

// API service

import { api } from './boot/axios'
import Settings from './settings'

// Setup axios to automatically send the token for each request
// and Refresh the token if it's expired
let isRefreshing = false
let refreshSubscribers = []
let refreshTokenUrl = 'https://securetoken.googleapis.com/v1/token?key=AIzaSyCbFPz8wEJUgFRB27u0RiqSBcdIkphaRj0'

function refreshAccessToken () {
  const data = {
    'grant_type': 'refresh_token',
    'refresh_token': localStorage.getItem('refresh_token') || ''
  }

  return api.post(refreshTokenUrl, data)
}

api.interceptors.request.use(function (config) {
  // attach token to all requests
  const token = localStorage.getItem('token')
  if (token && config.url !== refreshTokenUrl) {
    config.headers.common['Authorization'] = `Bearer ${token}`
    config.headers.common['Content-Type'] = 'application/json; charset=utf-8'
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Intercept 401 requests and redirect to the login component
api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response !== undefined) {
    // Redirect to the login component
    if (error.response.status === 401) {
      const originalRequest = error.config

      // If the refresh fails redirect to the login page
      if (originalRequest.url === refreshTokenUrl) {
        // Using router.push doesn't work when the caller has a custom error handler
        window.location.replace(Settings.loginUrl)
      }

      if (!isRefreshing) {
        isRefreshing = true
        refreshAccessToken().then(function (response) {
          const accessToken = response.data['access_token']

          // Store tokens
          localStorage.setItem('token', accessToken)
          localStorage.setItem('refresh_token', response.data['refresh_token'])

          // Retry failed requests
          isRefreshing = false
          onRefreshed(accessToken)
        }).catch(function (error) {
          // Using router.push doesn't work when the caller has a custom error handler
          window.location.replace(Settings.loginUrl)
          console.log(error)
        })
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(api(originalRequest))
        })
      })
      return retryOrigReq
    }
  }
  return Promise.reject(error)
})

function subscribeTokenRefresh (cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed (token) {
  refreshSubscribers.map(cb => cb(token))
}

export function getTodaysDownloads () {
  return api.get(Settings.todaysDownloads)
}

export function listDownloads (timestamp) {
  return api.get(Settings.DownloadsUrl + '?date=' + timestamp)
}

export function searchDownloads (text) {
  return api.get(Settings.searchDownloadsUrl + '?text=' + text)
}

export function updateDownload (downloadID, data) {
  return api.patch(`${Settings.DownloadsUrl}/${downloadID}`, data)
}

export function deleteDownload (downloadID) {
  return api.delete(Settings.DownloadsUrl + '/' + downloadID)
}

export function listPublicDownloads (username) {
  return api.get(Settings.PublicDownloadsUrl + '/' + username)
}

export function saveMedia (sourceUrl, data) {
  return api.post(sourceUrl, data)
}

export function updateDownloads (data) {
  return api.post(Settings.updateDownloadsUrl, data)
}

export function getTermsOfServiceHtml () {
  return api.get(Settings.termsOfServiceHtmlUrl)
}

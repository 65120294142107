<template>
  <q-drawer v-model="drawerState" @hide="hideDrawer()" :overlay="true">
    <q-list padding class="menu-list">
      <q-item style="padding-top: 20px; padding-bottom: 20px">
        <q-item-section avatar>
          <q-avatar size="70px">
            <q-img
              src="/icons/android-chrome-192x192.png"
              loading="lazy"
            ></q-img>
          </q-avatar>
        </q-item-section>
        <q-item-section class="text-body1">
          <div class="text-weight-bold" style="font-size: 18px">
            Downloader Bot
          </div>
          <div>
            <custom-svg-icon name="ion-logo-twitter" color="primary" />
            <a
              href="https://twitter.com/DownloaderBot"
              style="color: #027be3; text-decoration: none"
            >
              @DownloaderBot</a
            >
          </div>
        </q-item-section>
      </q-item>

      <q-separator />

      <q-item v-if="languageSwitcherIsVisible">
        <q-select
          v-model="lang"
          :options="langOptions"
          :label="$t('select_language')"
          dense
          borderless
          emit-value
          map-options
          options-dense
          style="width: 100%"
        />
      </q-item>

      <template v-for="(item, index) in drawerItems">
        <q-item
          v-if="item.display()"
          :key="index"
          clickable
          v-ripple
          @click="item.clickAction()"
        >
          <q-item-section avatar>
            <custom-svg-icon size="30px" :name="item.iconName" :color="item.color" />
          </q-item-section>
          <q-item-section
            class="text-body1 text-bold"
            :class="'text-' + item.color"
            >{{ item.label }}
            <div
              v-if="item.subText"
              style="font-weight: normal; font-size: 15px"
            >
              {{ item.subText }}
            </div>
          </q-item-section>
        </q-item>
      </template>

      <!-- q-item v-if="this.isLoggedIn" clickable v-ripple @click="this.logout">
        <q-item-section avatar>
          <q-icon size="30px" name="ion-log-out" color="negative" />
        </q-item-section>
        <q-item-section class="text-body1 text-bold text-negative">Logout</q-item-section>
      </q-item -->

      <!-- q-item @click="openURL('https://github.com/danidee10')">
          <q-item-section icon="ion-logo-github" />
          <q-item-label label="GitHub" sublabel="github.com/danidee10" />
      </q-item -->
    </q-list>
  </q-drawer>
</template>

<script>
/* global gtag */

import { openURL } from 'quasar'
import { useI18n } from 'vue-i18n'

import CustomSvgIcon from './CustomSvgIcon.vue'
import { askForNotificationPermission } from '../push-notifications'

export default {
  name: 'SideDrawer',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },

  components: {
    CustomSvgIcon
  },

  data () {
    const { locale } = useI18n({ useScope: 'global' })

    const langOptions = [
      { value: 'en', localeValue: 'en-US', label: '🇺🇸 English' },
      { value: 'pt', localeValue: 'pt-BR', label: '🇧🇷 🇵🇹 Português' },
      {
        value: 'ng',
        localeValue: 'en-NG',
        label: '🇳🇬 🇨🇲 🇬🇭 🇸🇱 Pidgin English'
      },
      { value: 'es', localeValue: 'es', label: '🇪🇸 España' },
      { value: 'ja', localeValue: 'ja', label: '🇯🇵 日本語' },
      { value: 'de', localeValue: 'de', label: '🇩🇪 Deutsch' },
      { value: 'cn', localeValue: 'zh-CN', label: '🇨🇳 中国人' },
      { value: 'fr', localeValue: 'fr', label: '🇫🇷 Français' }
    ]

    return {
      drawerState: this.modelValue,
      user: null,
      drawerItems: [],
      locale: locale,
      lang:
        langOptions.find((option) => option.localeValue === locale.value)
          ?.value || 'en',
      langOptions: langOptions
    }
  },

  mounted () {
    // Load the user from localStorage
    this.user = this.$user.getUser()
    this.drawerItems = this.generateSideBarItems()
  },

  computed: {
    routeLanguage () {
      return this.$route.params.language
    },
    languageSwitcherIsVisible () {
      return true
      // return this.user?.username === 'petropolithanos'
    }
  },

  methods: {
    generateSideBarItems () {
      return [
        {
          label: this.$t('home'),
          iconName: 'home',
          color: this.$route.name === 'Home' ? 'primary' : 'default',
          clickAction: this.goHome,
          display: () => true
        },
        {
          label: this.$t('sign_in'),
          iconName: 'login',
          color: this.$route.name === 'Login' ? 'primary' : 'default',
          clickAction: this.goToLogin,
          display: () => !this.isLoggedIn()
        },
        {
          label: this.$t('public_downloads'),
          iconName: 'download',
          color: this.$route.name === 'UserDownloads' ? 'primary' : 'default',
          clickAction: this.goToUserDownloads,
          subText: this.$t('public_downloads_visible_to_everyone'),
          display: () => {
            // Only display if there's a user with a username (From twitter)
            // Regular users don't have public downloads
            if (this.user) {
              if (this.user.username) {
                return true
              }
            }

            return false
          }
        },
        {
          label: this.$t('push_notifications'),
          iconName: 'notifications',
          color: 'default',
          clickAction: this.askForNotificationPermission,
          subText: this.$t('receive_push_notifications'),
          display: () => true
        },
        {
          label: 'Twitter',
          iconName: 'ion-logo-twitter',
          color: 'default',
          clickAction: () => openURL('https://twitter.com/DownloaderBot'),
          subText: this.$t('follow_us_on_twitter'),
          display: () => true
        },
        {
          label: this.$t('logout'),
          iconName: 'logout',
          color: 'negative',
          clickAction: this.logout,
          display: this.isLoggedIn
        }
      ]
    },

    hideDrawer () {
      this.drawerState = false
      this.$emit('update:modelValue', false)
    },

    goToLogin () {
      this.$router.push({
        name: 'Login',
        params: { language: this.routeLanguage }
      })
    },

    goHome () {
      if (this.isLoggedIn()) {
        this.$router.push({ name: 'Home' })
      } else {
        this.$router.push({
          name: 'Public',
          params: { language: this.routeLanguage }
        })
      }
    },

    goToUserDownloads () {
      this.$router.push({
        name: 'UserDownloads',
        params: { username: this.user.username }
      })
    },

    openURL,

    askForNotificationPermission () {
      let successCallback = (message) => {
        this.$q.notify({ color: 'green', icon: 'check', message: message })
        localStorage.setItem('subscribedToPushNotifications', 'Yes')
        this.subscribedToPushNotifications = 'Yes'
      }
      let errorCallback = (message) => {
        this.$q.notify({ color: 'red', icon: 'warning', message: message })
      }

      askForNotificationPermission(successCallback, errorCallback)
    },

    isLoggedIn () {
      return !!this.$user.isLoggedIn()
    },

    logout () {
      var self = this

      this.$q
        .dialog({
          title: this.$t('confirm'),
          message: this.$t('private.confirm_logout'),
          ok: this.$t('yes'),
          cancel: this.$t('no'),
          preventClose: true
        })
        .onOk(() => {
          self.$user.logout()
          self.$q.notify({ color: 'green', icon: 'check', message: 'Success!' })
          self.$router.push({ name: 'Public' })
        })
        .onCancel(() => {
          console.log('No!')
        })
    }
  },

  watch: {
    modelValue (value) {
      this.drawerState = value
      this.$emit('update:modelValue', value)
    },
    lang (lang) {
      const localeValue = this.langOptions.find(
        (option) => option.value === lang
      )?.localeValue
      this.locale = localeValue
      localStorage.setItem('locale', localeValue)
      this.drawerItems = this.generateSideBarItems()

      // dynamic import, so loading on demand only
      import(
        /* webpackInclude: /(de|en-US|fr|es|pt|ja|pt-BR|zh-CN)\.js$/ */
        'quasar/lang/' + localeValue
      ).then(lang => {
        this.$q.lang.set(lang.default)
      }).catch(error => {
        console.log(error)
        console.log(`language pack ${localeValue} not found`)
      })

      // Track language select with google analytics
      gtag('event', `select_language_${lang}`, {
        'event_category': 'language',
        'event_label': `Select ${lang} language`,
        'value': 1
      })

      // If the user is not logged in, Navigate to the appropriate language page
      if (!this.$user.isLoggedIn()) {
        let userLanguage = lang
        if (lang === 'en') {
          userLanguage = ''
        }

        this.$router.push({
          name: this.$route.name,
          params: { language: userLanguage }
        })
      }
    }
  }
}
</script>

<style>
.q-drawer {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>

// Utility functions

import { Dialog, exportFile } from 'quasar'

export default class Downloader {
  constructor (app) {
    this.app = app
  }

  forceDownload (blob, filename, successCallback) {
    exportFile(filename, blob)
    successCallback()
  }

  viewMedia (mediaSourceLink) {
    // View media on it's website/source
    var anchor = document.createElement('a')
    anchor.href = mediaSourceLink
    anchor.target = '_blank'

    document.body.appendChild(anchor)
    anchor.click()
    anchor.remove()
  }

  downloadMedia (name, mediaLink, successCallback, errorCallback) {
    // Add extension to name
    const extension = mediaLink.match(/\.(mp4|flv|avi)/)
    if (extension !== null) {
      name = name + extension[0]
    } else {
    // Use MP4 as the default
      name = name + '.mp4'
    }

    const dialog = Dialog.create({
      message: `${this.app.$t('downloading')}... 0%`,
      progress: true,
      persistent: false,
      ok: false
    })

    const self = this
    function progress ({ loaded, total }) {
      const percentage = Math.round(loaded / total * 100)

      // Update the dialog
      if (percentage === Infinity) {
        dialog.update({
          message: `${self.app.$t('downloading')}...`
        })
      } else {
        dialog.update({
          message: `${self.app.$t('downloading')}... ${percentage}%`
        })
      }
    }

    let xhr = new XMLHttpRequest()
    xhr.open('GET', mediaLink, true)
    xhr.responseType = 'blob'

    xhr.onprogress = event => {
      const loaded = event.loaded
      const total = event.total
      progress({ loaded, total })
      console.log(`Received ${loaded} of ${total}`)
    }

    xhr.onload = () => {
      if (xhr.status !== 200) {
        dialog.hide()
        Dialog.create({
          title: self.app.$t('alert'),
          message: self.app.$t('error.generic')
        })
        console.log(`Error ${xhr.status}: ${xhr.statusText}`)
      } else {
        setTimeout(() => {
          dialog.hide()
        }, 1000)
        dialog.update({
          message: self.app.$t('done')
        })

        const blob = xhr.response
        this.forceDownload(blob, name, successCallback)
      }
    }

    xhr.onerror = () => {
      dialog.hide()
      /*
      Dialog.create({
        title: self.app.$t('alert'),
        message: self.app.$t('error.generic')
      })
      */
      errorCallback()
    }

    xhr.send()
  }
}

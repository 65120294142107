<template>
  <q-banner v-show="updateAvailable" dense inline-actions class="text-white text-center bg-green">
    <template v-slot:avatar>
      <q-icon name="info" color="white" />
    </template>
    {{ $t('new_version_available') }}
    <template v-slot:action>
      <q-btn flat color="white" label="Update" @click="reloadApp" />
    </template>
  </q-banner>
</template>

<script>
export default {
  name: 'UpdatePWA',
  methods: {
    reloadApp () {
      // post message back to service worker
      window.postMessage({ action: 'updateApp', appVersion: this.appVersion })
    }
  }
}
</script>

import { createApp } from 'vue'

import { api } from './boot/axios'
import firebase from 'firebase/app'
import 'firebase/messaging'
import * as Sentry from '@sentry/vue'

const app = createApp({})
Sentry.init({
  app,
  dsn: 'https://caef15c6a3974d149faa1aa7d0425e63@sentry.io/1380809',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'runCustomize is not defined',
    'hasInject is not defined'
  ],
  allowUrls: [
    'https://videodownloaderbot.com'
  ]
})

export const askForNotificationPermission = async (successCallback, errorCallback) => {
  try {
    const messaging = firebase.messaging()

    await messaging.requestPermission()
    const token = await messaging.getToken()

    // store token on the server
    const userData = JSON.parse(localStorage.getItem('user'))
    api
      .post(
        '/store-notification-token',
        {
          username: userData.username,
          token: token
        }
      )
      .then(function (response) {
        successCallback(response.data['message'])
      })
      .catch(function (error) {
        Sentry.captureException(error)
        alert('An error occured please try again...')
      })
  } catch (error) {
    Sentry.captureException(error)
    errorCallback('A subscription error occured')
  }
}

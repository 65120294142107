// Setup credentials and config

const BASE_URL = process.env.BASE_URL

export default {
  baseUrl: BASE_URL,
  todaysDownloads: '/downloads/pwa/today',
  DownloadsUrl: '/downloads/pwa',
  searchDownloadsUrl: '/downloads/pwa/search',
  PublicDownloadsUrl: '/downloads/bot',
  MediaDownloadUrl: '/download-media',
  getMediaUrl: '/get-media-by-id',
  saveMediaUrl: '/save-media',
  updateDownloadsUrl: '/update-downloads',
  termsOfServiceHtmlUrl: '/templates/terms-of-service.html',
  loginUrl: 'https://videodownloaderbot.com/#/login'
}

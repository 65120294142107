// This file contains classes for various download sources. E.g Youtube, Facebook, Twitter etc

import { api } from './boot/axios'

import Settings from './settings'
import { updateDownload } from './api'
import Downloader from './utils'

const RefreshDownloadMixin = {
  // This mixin adds the refresh functionality to media sources that expire
  // E.g Youtube, Facebook and Tiktok. The urls for this sources expire
  // After a certain time and needs to be refreshed

  refreshMedia (media, action) {
    // Refreshes the media if necessary and/or calls the 'action'
    const self = this
    media.downloading = true // Triggers loading effect

    if (this.isMediaExpired(media)) {
      this.getMedia(media.media_parent_url).then(function (response) {
        let data = response.data
        data = self.transformData(data)
        data = {
          media_url: data.media_link,
          media_screenshot_link: data.screenshot_link
        }

        updateDownload(media.ID, data).then(function (response) {
          media.downloading = false
          Object.assign(media, data)
          media.UpdatedAt = response.data.UpdatedAt
          action(media)
        }).catch(function (error) {
          console.log(error)
          alert('An error occured please try again')
          media.downloading = false
          // self.$sentry.captureException(error)
        })
      }).catch(function (error) {
        // Set image to error image
        console.log(error)
        alert('An error occured please try again')
        media.downloading = false
        media.media_screenshot_link = 'https://via.placeholder.com/150/FF0000/FFFFFF?text=Error'
        // self.$sentry.captureException(error)
      })
    } else {
      media.downloading = false
      action(media)
    }
  },

  viewMedia (media) {
    this.refreshMedia(media, (media) => this.downloader.viewMedia(media.media_url))
  },

  downloadMedia (media) {
    this.refreshMedia(media, (media) => this.download(media))
  }
}

class DownloadSource {
  // .name is mangled by uglifyJS in production so we can't rely on it
  // see https://stackoverflow.com/questions/50267543/class-name-always-e-for-every-class-after-uglify-webpack-for-production
  static id
  static regex
  static icon

  getMediaUrl = Settings.baseUrl + '/get-media'

  constructor (app) {
    this.app = app
    this.downloader = new Downloader(app)
  }

  isMediaExpired (media) {
    return false
  }

  getMedia (url) {
    // Method for fetching the media from the url
    const baseURL = api.defaults.baseURL
    api.defaults.baseURL = '/'
    let mediaUrl = new URL(this.getMediaUrl)
    mediaUrl.searchParams.append('url', url)
    mediaUrl.searchParams.append('platform', this.id)

    const prom = api.get(mediaUrl.href)
    // restore axios baseURL
    api.defaults.baseURL = baseURL

    return prom
  }

  transformData (data) {
    return data
  }

  viewMedia (media) {
    // This function is called before a media is opened
    // and can be used to transform the url before opening it or
    // Refresh the media if it's expired
    this.downloader.viewMedia(media.media_url)
  }

  download (media) {
    this.downloader.downloadMedia(
      media.name || media.media_url, media.media_url,
      () => { media.downloading = false },
      this.viewMedia
    )
  }

  downloadMedia (media) {
    // This function is called before a media is downloaded
    // and can be used to transform the url or refresh the media
    // before opening it
    this.download(media)
  }
}

export class Twitter extends DownloadSource {
  static id = 'Twitter'
  static regex = /(https:\/\/twitter.com\/.+\/status\/\d+)/
  static downloadMediaUrl = Settings.baseUrl + '/twitter/download-media'
  static icon = 'ion-logo-twitter'
  static iconColor = 'blue'

  getMediaUrl = Settings.baseUrl + '/twitter/get-media'
}

export class Instagram extends DownloadSource {
  static id = 'Instagram'
  static regex = /(https:\/\/www.instagram.com\/.+\/)/
  static downloadMediaUrl = Settings.baseUrl + '/instagram/download-media'
  static icon = 'ion-logo-instagram'
  static iconColor = 'blue'
}

export class Youtube extends DownloadSource {
  static id = 'Youtube'
  static regex = /((youtube.com|youtu.be)\/(watch.+|.+))/
  static downloadMediaUrl = Settings.baseUrl + '/youtube/download-media'
  static icon = 'ion-logo-youtube'
  static iconColor = 'red'

  constructor (app) {
    super(app)

    this.viewMedia = RefreshDownloadMixin.viewMedia.bind(this)
    this.refreshMedia = RefreshDownloadMixin.refreshMedia.bind(this)
    this.downloadMedia = RefreshDownloadMixin.downloadMedia.bind(this)
  }

  isMediaExpired (media) {
    const parsedUrl = new URL(media.media_url)
    const expire = parsedUrl.searchParams.get('expire')
    const now = new Date()

    return (now.getTime() / 1000) > parseInt(expire)
  }

  download (media) {
    // Youtube automatically downloads the media if you append a title to the url
    const mediaUrl = media.media_url + '&title=' + encodeURIComponent(media.name)

    var a = document.createElement('a')
    a.target = '_blank'
    a.href = mediaUrl
    document.body.appendChild(a)
    a.click()
    a.remove()
    // window.close() // Force close the window on Chrome mobile
  }
}

export class Facebook extends DownloadSource {
  static id = 'Facebook'
  static regex = /(facebook.com\/.+\/videos.+)/
  static downloadMediaUrl = Settings.baseUrl + '/facebook/download-media'
  static icon = 'ion-logo-facebook'
  static iconColor = 'blue'

  constructor (app) {
    super(app)

    this.viewMedia = RefreshDownloadMixin.viewMedia.bind(this)
    this.refreshMedia = RefreshDownloadMixin.refreshMedia.bind(this)
    this.downloadMedia = RefreshDownloadMixin.downloadMedia.bind(this)
  }

  isMediaExpired (media) {
    const sixHours = 21600000 // Milliseconds
    const timeDifference = new Date().getTime() - new Date(media.UpdatedAt).getTime()

    return timeDifference >= sixHours
  }
}

export class TikTok extends DownloadSource {
  static id = 'TikTok'
  static regex = /(https:\/\/.*tiktok.com\/.*\/video\/.+)/
  static downloadMediaUrl = Settings.baseUrl + '/tiktok/download-media'
  static icon = 'ion-logo-tiktok'
  static iconColor = 'black'
}

export const DOWNLOAD_SOURCES = [Twitter, Instagram, Facebook, Youtube, TikTok]

export function getDownloadSource (media) {
  const DownloadSource = DOWNLOAD_SOURCES.find((source) => source.id.toLowerCase() === media.platform.toLowerCase())
  if (DownloadSource) {
    return DownloadSource
  }

  return null
}

export function fetchMediaUsingSource (app, rawText, callerCallback) {
  let found = false
  let mediaUrl = ''
  let DownloadSource = null
  let media = {}
  const user = app.$user.getUser()

  for (const source of DOWNLOAD_SOURCES) {
    let matchedUrl = rawText.match(source.regex)
    if (matchedUrl !== null) {
      mediaUrl = matchedUrl[1]
      found = true
      DownloadSource = source
      break
    }
  }

  if (!found) {
    const error = app.$t('private.no_link_shared')
    if (user.username === 'petropolithanos') {
      alert(rawText)
    }
    callerCallback({ error, DownloadSource, media })
  }

  DownloadSource = new DownloadSource(app)
  DownloadSource.getMedia(mediaUrl).then(function (response) {
    let data = response.data
    data = DownloadSource.transformData(data)

    if (data === null) {
      const error = app.$t('private.no_link_shared')
      if (user.username === 'petropolithanos') {
        alert('Could\'nt transform data')
        alert(rawText)
        alert(mediaUrl)
      }
      callerCallback({ error, DownloadSource, media })
    }

    data['source_link'] = mediaUrl
    if (data?.media_link) {
      const error = null
      data['media_url'] = data['media_link']
      data['name'] = data['full_text']
      callerCallback({ error, DownloadSource, media: data })
    } else {
      var error = app.$t('private.invalid_shared_post')
      callerCallback({ error, DownloadSource, media })
      app.$sentry.captureMessage(error)
    }
  }).catch(function (error) {
    if (user.username === 'petropolithanos') {
      alert('Unspecified error')
      alert(rawText)
      alert(mediaUrl)
      console.log(error)
    }
    callerCallback({ error, DownloadSource, media })
    app.$sentry.captureException(error)
    app.$errorDialog(app.$t('error.generic'))
  })
}
